import FE_PC from "img/logos/FE_PC.jpg";
import RP from "img/logos/RP.png";
import MKiDN from "img/logos/MKiDn.png";
import UE_EFRR from "img/logos/UE_EFRR.jpg";

export default function FooterLogos() {
		
	return (
		<div className="footer__logos f-ac-jsb">
			<img src={ FE_PC } alt="Fundusze Europejskie Polska Cyfrowa"/>
			<img src={ RP } alt="Rzeczpospolita Polska" />
			<img src={ MKiDN } alt="Ministerstwo Kultury i Dziedzictwa" />
			<img src={ UE_EFRR } alt="Unia Europejska Europejski Funducz Rozwoju Regionalnego" />
		</div>
	)
}

