import { useLocation } from "react-router-dom";
import PATHS from "constants/paths";
import useWindowResize from "hook/useWindowResize";
import MobileNavigation from "components/navigation/MobileNavigation"

export default function MobileLayout({ children }) {

	const location = useLocation();
	const padding = useWindowResize( setMainPadding );
	const is_player_page = location.pathname === PATHS.PLAYER;

	function setMainPadding() {
		const mobile_nav = document.querySelector( "#mobile-nav" );
		return mobile_nav?.offsetHeight || 0;
	}

	return (
		<main style={{ paddingBottom: is_player_page ? 0 : padding + "px" }}>
			{ children }
			{ !is_player_page && <MobileNavigation/> }
		</main>
	)
}

