import PATHS from "./paths";

export default {
	ABOUT: "O projekcie",
	ARCHIVES: "Lokalizacje",
	ARTICLES: "Czytelnia",
	COLLECTIONS: "Kolekcje",
	FAVORITES: "Ulubione",
	TIMELINE: "Oś czasu",
	PLAYER: "Odtwarzacz muzyki",

	[ PATHS.ABOUT ]: "O projekcie",
	[ PATHS.ARCHIVES ]: "Lokalizacje",
	[ PATHS.ARTICLES ]: "Czytelnia",
	[ PATHS.COLLECTIONS ]: "Kolekcje",
	[ PATHS.FAVORITES ]: "Ulubione",
	[ PATHS.TIMELINE ]: "Oś czasu",
	[ PATHS.PLAYER ] : "Odtwarzacz muzyki"
}