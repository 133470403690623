import { 
	getArticleProps,
	getArchiveProps,
	getAuthorProps, 
	getCompositionProps
} from "extra/props_format";

export default {

	post: {
		name: "post",
		up_name: "Article",
		dataFormatHandler: getArticleProps
	},

	archive: { 
		name: "archive", 
		up_name: "Archive", 
		dataFormatHandler: getArchiveProps 
	},

	author: { 
		name: "author", 
		up_name: "Author", 
		dataFormatHandler: getAuthorProps 
	},

	object: { 
		name: "object", 
		up_name: "Object", 
		dataFormatHandler: getCompositionProps 
	}
}