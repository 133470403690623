import ExternalLink from "components/general/ExternalLink"
import SocialMedia from "components/general/SocialMedia";
import search_svg_src from "svg/search.svg";

export default function FooterTop({ footer_title, desc_under_title, button_url, socials }) {
		
	return (
		<div className="footer__top">
			<div className="container f-ac" style={{ backgroundImage: `url(${ search_svg_src })` }}>

				<div className="footer__top_search f-ac">

					<div>
						<strong> { footer_title } </strong>
						<span> { desc_under_title } </span>
					</div>

					{ button_url && 
						<ExternalLink href={ button_url }>
							Przejdź do stony repozytorium
						</ExternalLink>
					}
				</div>

				<div className="footer__top_socials f-ae">
					<span> © Narodowy Instytut Fryderyka Chopina { new Date().getFullYear()} </span>

					<SocialMedia socials={ socials }/>
				</div>
			</div>
		</div>
	)
}

