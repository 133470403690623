import { Link, useLocation } from 'react-router-dom';
import { useDevice } from 'context/DeviceContext';
import PATHS from 'constants/paths';
import PAGE_TITLES from "constants/page_titles";

import { ArchiveIcon, HeartIcon, LogoIcon, ScrollIcon, TimelineIcon } from "svg/icons";
import "./navigation.scss";

const navigation = [
	{
		to: "/",
		icon: <LogoIcon/>,
		label: "Home"
	},
	{
		to: PATHS.TIMELINE,
		icon: <TimelineIcon/>,
		label: PAGE_TITLES.TIMELINE
	},
	{
		to: PATHS.ARCHIVES,
		icon: <ArchiveIcon/>,
		label: PAGE_TITLES.ARCHIVES
	},
	{
		to: PATHS.COLLECTIONS,
		icon: <ScrollIcon/>,
		label: PAGE_TITLES.COLLECTIONS
	},
	{
		to: PATHS.ABOUT,
		icon: <LogoIcon/>,
		label: PAGE_TITLES.ABOUT
	},
	{
		to: PATHS.FAVORITES,
		icon: <HeartIcon/>,
		label: PAGE_TITLES.FAVORITES
	}
]

export default function Navigation( props ) {
	
	const location = useLocation();
	const device = useDevice();
	
	const isItemActive = path => {
		if ( path === location.pathname ) return true;

		if ( path !== "/" && location.pathname.search( path.replace("/", "" )) !== -1 )
			return true;

		return false;	
	}

	return (
		<nav className={`navigation f-ac ${ props.extra_classes || "" } `}>
			{ navigation.map( nav => {
				
				if ( nav.to === "/" && device === "desktop" ) return null;
				if ( nav.to === PATHS.ABOUT && device === "mobile" ) return null;

				const is_active = isItemActive( nav.to ); 

				const link_classes = [ "f-c-ac-jc", is_active ? "active" : "" ].join(" ");
				const link_props = { to: nav.to, className: link_classes }

				return (
					<Link key={ nav.to } {...link_props }> 
						{ nav.icon }
						<span> { nav.label } </span> 
					</Link>
				)
			})}
		</nav>
	)
}

