import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import PATHS from "constants/paths";
import Loader from 'components/general/Loader';

const MainPage = lazy(() => import( 'pages/MainPage' ));
const AboutPage = lazy(() => import( 'pages/AboutPage' ));
const ArticlesPage = lazy(() => import( "pages/ArticlesPage" ));
const ArticleSinglePage = lazy(() => import( "pages/ArticleSinglePage" ));
const ArchivesPage = lazy(() => import( 'pages/ArchivesPage' ));
const ArchiveSinglePage = lazy(() => import( 'pages/ArchiveSinglePage' ));
const AuthorSinglePage = lazy(() => import( 'pages/AuthorSinglePage' ));
const CollectionsPage = lazy(() => import( 'pages/CollectionsPage' ));
const CollectionSinglePage = lazy(() => import( 'pages/CollectionSinglePage' ));
const CompositionSinglePage = lazy(() => import( "pages/CompositionSinglePage" ));
const FavoritesPage = lazy(() => import( 'pages/FavoritesPage' ));
const TimelinePage = lazy(() => import( 'pages/TimelinePage' ));
const EraSinglePage = lazy(() => import( 'pages/EraSinglePage' ));
const PlayerPage = lazy(() => import( 'pages/PlayerPage' ));

export default function Routes() {
	return (
		<Suspense fallback={ <Loader style={{ margin: "auto" }}/> }>
			<Route exact path="/" component={ MainPage }/>
			<Route exact path={ PATHS.ABOUT } component={ AboutPage }/>
			<Route exact path={ PATHS.ARTICLES } component={ ArticlesPage }/>
			<Route exact path={ PATHS.ARTICLE_SINGLE()} component={ ArticleSinglePage }/>
			<Route exact path={ PATHS.ARCHIVES } component={ ArchivesPage }/>
			<Route exact path={ PATHS.ARCHIVE_SINGLE()} component={ ArchiveSinglePage }/>
			<Route exact path={ PATHS.AUTHOR_SINGLE()} component={ AuthorSinglePage }/>
			<Route exact path={ PATHS.COLLECTIONS } component={ CollectionsPage }/>
			<Route exact path={ PATHS.COLLECTION_SINGLE()} component={ CollectionSinglePage } />
			<Route exact path={ PATHS.COMPOSITION_SINGLE()} component={ CompositionSinglePage }/>
			<Route exact path={ PATHS.FAVORITES } component={ FavoritesPage }/>
			<Route exact path={ PATHS.TIMELINE } component={ TimelinePage }/>
			<Route exact path={ PATHS.ERA_SINGLE()} component={ EraSinglePage }/>
			<Route exact path={ PATHS.PLAYER } component={ PlayerPage }/>
		</Suspense>
	)
}

