import { useLocation } from "react-router-dom";
import PATHS from "constants/paths";

import Footer from 'components/main/Footer';
import Header from 'components/main/Header';

export default function DesktopLayout( props ) {
	
	const player_page = PATHS.PLAYER === useLocation().pathname;

	return (
		<>
			{ !player_page && <Header/> }

			<main>
				{ props.children }
			</main>

			{ !player_page && <Footer/> }
		</>
	)
}

