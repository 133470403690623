import { useCachedData } from "context/CacheDataContext";
import CACHE from "constants/cache";

import FooterTop from "./inner/FooterTop";
import FooterLogos from "./inner/FooterLogos";

import "./footer.scss";

export default function Footer() {

	const data = useCachedData( CACHE.HOMEPAGE )?.data;
	const footer_fields = data?.acf?.footer || {};

	return (
		<footer className="footer">
			<FooterTop { ...footer_fields } />
			<FooterLogos/>
		</footer>
	)
}

