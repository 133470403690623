import axios from "axios";
import PAGES from "./pages";
import POST_TYPES from "./post_types";
import TAXONOMIES from "./taxonomies";
import { getPageProps } from "extra/props_format";
import { setPageTitle } from "extra/functions";

const API = axios.create({ baseURL: window.config.ORIGIN + "/index.php/wp-json/api/v1" })

API.getCancelTokenSource = () => axios.CancelToken.source();

API.interceptors.response.use( res => {

	const url_arr = res.config.url.match(/[^/]+/g);
	const is_taxonomy = url_arr[0] === "terms";
	const is_single = !is_taxonomy && +url_arr[1] >= 1;
	const data_format_handler_k = is_taxonomy ? url_arr[ 1 ] : url_arr[ 0 ].replace(/s$/, '' );

	const data_origin = is_taxonomy 
		? res.data
		: is_single ? res.data.post : res.data.posts;

	const dataFormatHandler = 
		POST_TYPES[ data_format_handler_k ]?.dataFormatHandler || 
		TAXONOMIES[ data_format_handler_k ]?.dataFormatHandler || 
		getPageProps;

	const data = Array.isArray( data_origin ) 
		? data_origin.map( d => dataFormatHandler( d )) 
		: dataFormatHandler( data_origin ); 	
	
	is_single && data.type !== "page" && data.title && setPageTitle( data.title );

	return { 
		data, 
		posts_count: res.data?.post_count, 
		max_num_pages: res.data?.max_num_pages 
	}
})



const createPageMethod = ({ name, id }) => {
	API[`get${ name }`] = opt => API.get(`/page/${ id }`, { cancelToken: opt?.cancelToken })
}


const createPostTypeMethods = ({ name, up_name }) => {

	/*
	~ page - Find items by page (Default: 0)
	~ perPage - Select how much items will be displayed on page (Default: 12)
	~ order - Sort items (Default: ASC)	
	~ termName - Search items by term name (required termValue)
	~ termValue - Search items by term value (required termName)
	~ archiveId - for Objects filter by archive ID
	~ authorId - for Objects filter by author ID
	*/

	API[`get${ up_name }s`] = opt => {

		const params = opt?.params || {};

		return API.get(`/${ name }s`, { 
			params: {
				perPage: params.perPage || 99,
				...params
			},
			cancelToken: opt?.cancelToken
		})
	}


	API[`get${ up_name }`] = ( id, cancelToken ) => {
		return API.get(`/${ name }/${ id }`, { cancelToken })
	}
}


const createTaxonomyMethods = ({ name, taxonomy }) => {
	API[`get${ name }s`] = opt => API.get(`/terms/${ taxonomy }`, { cancelToken: opt?.cancelToken })
}


PAGES.forEach( p => createPageMethod( p ));
Object.values( POST_TYPES ).forEach( post_type => createPostTypeMethods( post_type ));
Object.values( TAXONOMIES ).forEach( tax => createTaxonomyMethods( tax ));

export default API;