
export const cropString = ( str, max_length ) => {
	return str.length > max_length
		? str.slice( 0, max_length ) + "..."
		: str
}


export const playerMsToMinutesSeconds = ms => {

	if ( !ms ) return "";

	const duration = Math.floor( ms );
	const minutes = Math.floor( duration / 60 );
	let seconds = duration % 60;
	if ( seconds <= 9 ) seconds = "0" + seconds;

	return `${ minutes }:${ seconds }`;
}


export const getThumbnail = ( thumb_arr, size ) => {

	if ( !thumb_arr || !thumb_arr?.length ) return "";
	const full_size_src = thumb_arr[ thumb_arr.length - 1 ]?.src; 

	return !size 
		? full_size_src
		: thumb_arr.find( i => i.size === size )?.src || full_size_src;
}


export const backToTopSmooth = () => { 
	"scrollBehavior" in document.documentElement.style
		? window.scrollTo({ top: 0, behavior: 'smooth' })
		: window.scrollTop = 0;
}


export const setPageTitle = text => {
	const page_title = text ? text + " - DMP - Dziedzictwo Muzyki Polskiej" : "DMP - Dziedzictwo Muzyki Polskiej";
	document.head.querySelector("title").innerText = page_title; 
}