export default {
	HOMEPAGE: "home_page",
	ABOUT: "about_page",
	ARCHIVES: "archives",
	AUTHORS: "authors",
	ERAS: "eras",
	COLLECTIONS: "collections",
	GENRES: "genres",
	LAST_ARTICLES: "last_articles",
	LAST_COMPOSITIONS: "last_compositions"
}