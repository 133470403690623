import { 
	getEraProps, 
	getCollectionProps,
	getGenreProps
} from "extra/props_format";

export default {
	epoki: { 
		name: "Era",
		single: "Epoka", 
		taxonomy: "epoki", 
		dataFormatHandler: getEraProps 
	},

	kolekcje: { 
		name: "Collection",
		single: "Kolekcja",
		taxonomy: "kolekcje", 
		dataFormatHandler: getCollectionProps 
	},

	gatunki: {
		name: "Genre",
		single: "Gatunek",
		taxonomy: "gatunki",
		dataFormatHandler: getGenreProps
	}
}
 