import React, { useMemo, useContext } from 'react';
import useWindowResize from "hook/useWindowResize";

import DesktopLayout from 'layout/DesktopLayout';
import MobileLayout from 'layout/MobileLayout';

const DeviceContext = React.createContext();
export default DeviceContext;

export const DeviceChecker = props => {
	
	const getDevice = () => window.innerWidth <= 1060 ? "mobile" : "desktop";
	const device = useWindowResize( getDevice );

	const Layout = useMemo(() => device === "desktop" 
		? DesktopLayout 
		: MobileLayout
	, [ device ]);

	return (
		<DeviceContext.Provider value={ device }>
			<Layout> { props.children } </Layout>
		</DeviceContext.Provider>
	)
}

export const useDevice = () => {
	const device = useContext( DeviceContext );
	return device;
}


export const useMobileDevice = () => {
	return useDevice() === "mobile"
}