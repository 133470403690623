import { Link } from "react-router-dom";
import PATHS from "constants/paths";
import PAGE_TITLES from "constants/page_titles";

import UE_small from "img/logos/UE_small.jpg";
import "./about-project-link.scss";

export default function AboutProjectLink() {
	
	return (
		<Link to={ PATHS.ABOUT } className="about-project-link f-c-ac">
			<img src={ UE_small } alt="Unia Europejska" />
			<span> { PAGE_TITLES.ABOUT } </span>
		</Link>
	)
}

