import { getThumbnail, cropString } from "extra/functions";

const getAllImgSizes = thumb => ({
	img: getThumbnail( thumb ),
	img_thumbnail: getThumbnail( thumb, "thumbnail" ),
	img_medium: getThumbnail( thumb, "medium" ),
	img_medium_large: getThumbnail( thumb, "medium_large" ),
	img_large: getThumbnail( thumb, "large" ),
})


const getTermProps = ( term, type ) => {

	const acf = term.acf;
	const description = term.description;
	const desc_cropped_length = type === "era" ? 200 : 120; 
	
	const props = {
		type: type,
		ID: term.term_id,
		title: term.name,
		taxonomy: term.taxonomy,
		...getAllImgSizes( acf?.thumbnail ),
		description: description,
		description_cropped: cropString( description || "", desc_cropped_length ),
	}

	if ( type === "era" ) {

		const featured = acf?.featured ? acf.featured.map( i => getCompositionProps( i )) : [];
		const genres = acf?.gatunki ? acf?.gatunki.map( g => getGenreProps( g )) : []; 

		props.genres = genres;
		props.featured = featured; 
		props.years = ( acf?.date_from || "" ) + "-" + ( acf?.date_to || "" );
		props.date_from = acf?.date_from;
	}

	return props;
}


const getSamePostProps = ( post, di ) => ({
	ID: post.ID,
	title: post.post_title,
	content: post.post_content || "",
	...getAllImgSizes( post.thumbnail ),
	default_img: di
})


export const getEraProps = era => getTermProps( era, "era" );

export const getCollectionProps = collection => getTermProps( collection, "collection" ); 

export const getGenreProps = genre => getTermProps( genre, "genre" );


export const getPageProps = page => {
	return {
		type: "page",
		...getSamePostProps( page ),
		acf: page.acf
	}
}


export const getAuthorProps = author => {

	const acf = author.acf;

	const years = acf?.birth_death_date || "";
	const bio = acf?.bio_summary || "";
	const find_out_more = acf?.find_out_more || "";
	const find_out_more_url = acf?.find_out_more_url || "";
	const featured = acf?.featured ? acf.featured.map( item => getCompositionProps( item )) : [];

	return {
		type: "author",
		...getSamePostProps( author, require( "img/author_placeholder.jpg" )),
		years, bio, find_out_more,
		find_out_more_url,
		featured
	}
}


export const getArchiveProps = archive => {

	const { acf } = archive;
	const virtual_tour_url = acf?.virtual_tour_url;
	const address_contact_details = acf?.informations?.address_contact_details;
	const archive_page_url = acf?.informations?.archive_page_url;
	const opening_hours = acf?.informations?.opening_hours;

	return {
		type: "archive",
		...getSamePostProps( archive ),
		virtual_tour_url, 
		address_contact_details, 
		archive_page_url, 
		opening_hours,
		objects_amount: archive.objects_amount,
	}
}


const getTrackProps = ( track, author_title ) => {

	const file = track.file;
	const file_pics = file?.sizes;

	return {
		ID: file.ID,
		audio_src: file?.url,
		title: track.song_title,
		author: author_title || "",
		performer: track.performer,
		genre: track.genre?.name,
		thumbnail: file_pics?.thumbnail,
		img: file_pics?.large
	}
}


export const getCompositionProps = composition => {

	const era = composition?.terms ? composition?.terms?.filter( t => t.taxonomy === "epoki" )?.map( t => t.name )?.join(", ") : ""
	const acf = composition?.acf;
	const author = acf?.author?.[0] ? getAuthorProps( acf.author[ 0 ]) : null;
	const author_title = author?.title || ""; 
	const collection_place_stored = acf?.collection_place_stored ? acf?.collection_place_stored?.map( c => c.post_title )?.join(", ") : null;
	const gallery = acf?.gallery ? acf?.gallery?.map( p => ({ src: p.url, thumbnail: p?.sizes?.thumbnail, alt: p.caption })) : null; 
	const songs = acf?.songs ? acf?.songs?.map( s => getTrackProps( s, author_title )) : null;

	return {
		type: "composition",
		...getSamePostProps( composition, require( "img/composition_default.png" )),
		era,
		author,
		author_title,
		collection_place_stored,
		gallery,
		publisher: acf?.publisher,
		signature: acf?.signature,
		songs
	};
}


export const getFavoriteProps = c => ({
	type: "composition",
	ID: c.ID,
	title: c.title,
	author_title: c.author_title,
	era: c.era,
	img_thumbnail: c.img_thumbnail,
	default_img: c.default_img,
	favorite: true
});


export const getArticleProps = a => ({
	type: "article",
	...getSamePostProps( a ),
	featured: a?.acf?.featured ? a.acf.featured.map( item => getCompositionProps( item )) : []
})