export default {
	ABOUT: "/about",
	ARCHIVES: "/locations",
	ARTICLES: "/articles",
	ARTICLE_SINGLE: ( id = ":id" ) => `/article/${ id }`,
	ARCHIVE_SINGLE: ( id = ":id" ) => `/location/${ id }`,
	AUTHOR_SINGLE: ( id = ":id" ) => `/author/${ id }`,
	COLLECTIONS: "/collections",
	COLLECTION_SINGLE: ( id = ":id" ) => `/collection/${ id }`,
	COMPOSITION_SINGLE: ( id = ":id" ) => `/composition/${ id }`,
	FAVORITES: "/favorites",
	TIMELINE: "/timeline",
	ERA_SINGLE: ( id = ":id" ) => `/timeline/${ id }`,
	PLAYER: "/player"
}