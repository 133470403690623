import API from "api/API";
import { createContext, useContext, useState } from "react";
import CACHE from "constants/cache";

const cache = {};
const CacheDataContext = createContext();


const getAPIMethod = key => {

	switch ( key ) {
		case CACHE.HOMEPAGE: return API.getHomePage;
		case CACHE.ABOUT: return API.getAboutPage;
		case CACHE.ARCHIVES: return API.getArchives;
		case CACHE.AUTHORS: return API.getAuthors;
		case CACHE.ERAS: return API.getEras;
		case CACHE.COLLECTIONS: return API.getCollections;
		case CACHE.LAST_ARTICLES: return () => API.getArticles({ params: { perPage: 12 } })
		case CACHE.LAST_COMPOSITIONS: return () => API.getObjects({ params: { perPage: 12 } })
	}
}


export const CacheDataContextProvider = props => {

	const update = useState()[1];

	const updateCache = ( key, data ) => {
		cache[ key ] = data;
		update( data );
	}


	const getCachedData = key => {
		
		if ( cache[ key ] === "loading" ) return null;
		if ( cache[ key ]) return cache[ key ];
	
		cache[ key ] = "loading";

		const APIMethod = getAPIMethod( key );
		if ( !APIMethod ) return;

		APIMethod()
		.then( response => updateCache( key, response ));

		return null;
	}


	return (
		<CacheDataContext.Provider value={ getCachedData }>
			{ props.children }
		</CacheDataContext.Provider>
	)
}


export const useCachedData = ( key ) => {
	const getCachedData = useContext( CacheDataContext );
	return getCachedData( key );
}