import Logo from "components/general/Logo";
import Navigation from 'components/navigation/Navigation';
import AboutProjectLink from "components/navigation/AboutProjectLink";

import "./header.scss";

export default function Header( props ) {

	return (
		<header className="header">
			<div className="container f-ac">
				<Logo link/>
				<Navigation/>
				<AboutProjectLink/>
			</div>
		</header>
	)
}

