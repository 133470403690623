import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DeviceChecker } from "context/DeviceContext";
import { CacheDataContextProvider } from "context/CacheDataContext";
import { backToTopSmooth, setPageTitle } from "extra/functions";
import Routes from "routes/Routes";
import PAGE_TITLES from "constants/page_titles";

import "styles/main.scss";

export default function App() {

	const location = useLocation();

	useEffect(() => {

		backToTopSmooth();
		( PAGE_TITLES[ location.pathname ] || location.pathname === "/") && setPageTitle( PAGE_TITLES[ location.pathname ])
	
	}, [ location.pathname ])

	return (
		<CacheDataContextProvider>
			<DeviceChecker>
				<Routes/>
			</DeviceChecker>
		</CacheDataContextProvider>
	)
}