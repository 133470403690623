import Navigation from "../Navigation";
import "./mobile-navigation.scss";

export default function MobileNavigation() {
		
	return (
		<div 
			id="mobile-nav" 
			className="mobile-navigation-container f-ac-jc"
		>
			<Navigation/>
		</div>
	)
}

