import { Link } from "react-router-dom";
import { LogoIcon } from 'svg/icons';
import "./logo.scss";

const LogoContent = () => (
	<>
		<LogoIcon/>
		<span> Dziedzictwo <br /> Muzyki Polskiej </span>
	</>
) 

export default function Logo( props ) {

	return props.link ? (
		<Link to="/" className="logo f-ac">
			<LogoContent/>
		</Link>
	) : <div className="logo f-ac"> <LogoContent/> </div>
}

