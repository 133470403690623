import { FacebookIcon, InstagramIcon, TwitterIcon, YouTubeIcon } from "svg/social";

const ICONS = {
	facebook: <FacebookIcon/>,
	instagram: <InstagramIcon/>,
	youtube: <YouTubeIcon/>,
	twitter: <TwitterIcon/>,
}


export default function SocialMedia({ socials }) {
		
	return (
		<ul className="social-media f-ac">
			{ socials && !!socials.length &&  
				socials.map(({ icon, media_url }) => (
					<li key={ media_url }>
						<a 
							href={ media_url }
							target="_blank" 
							title={ media_url }
							className="f-ac-jc"
						>
							{ ICONS[ icon ] || "" }
							<span className="d-none">{ media_url } </span>
						</a>
					</li>
				))
			}	
			
		</ul>
	)
}

